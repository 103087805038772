.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 60px;
  padding: 10px 20px;
  box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.05);
}

.logo {
  height: 25px;
}

.topRight {
  display: flex;
  align-items: center;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.topbarIconContainer.divider-right {
  border-right: 0.5px solid #6f96aa;
  padding: 0 20px;
}

.topIconBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.topbarIconContainer.avatar {
  padding: 0 20px;
}
