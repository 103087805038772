.sidebar {
  flex: 1;
  height: calc(150vh - 10px);
  background-color: #f8f8f8;
  position: sticky;
  top: -20px;
}

.sidebarWrapper {
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 15px 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.sidebarMenu a {
  font-size: 14px;
  color: #979797;
  text-decoration: none;
}

.sidebarMenu a svg {
  padding-right: 10px;
  fill: #6f96aa;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}
