body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

td,
th>span {
  font-size: 14px;
  font-weight: normal;
  color: black;
}

thead {
  border-bottom: 2px solid #e5e5e5;
}

thead th,
tbody td {
  padding: 8px !important;
}

.flex {
  display: flex;
}

.flex-1 {
  display: flex;
  flex: 1 1 0%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.two-columns-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2em;
  margin-top: 1em;
}

.other-info-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.2em;
  margin-top: 1em;
}

.full-width-grid {
  display: grid;
  gap: 1.2em;
}

.inputWrapper {
  display: flex;
  flex: 1;
  align-items: center;
}

.label,
.inlineEdit label {
  display: flex;
  background-color: #f4f5f7;
  padding: 5px 8px;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 10px;
  height: 30px;
  width: 250px;
  align-items: center;
}

.input {
  display: flex;
  flex: 2;
}

.input input {
  background-color: white;
}

.input textarea {
  background-color: white !important;
  border-radius: 10px !important;
}

.input input:disabled {
  border: 2px solid #dfe1e6;
}

.non-edit-input input {
  border: 0 !important;
  background-color: white;
  -webkit-text-fill-color: black !important;
  cursor: default;
}

.fitlov-select {
  width: 100%;
}

.places-autocomplete {
  width: 100%;
  border-color: rgb(255, 255, 255);
  border-radius: 3px;
  padding: 8px 6px;
  border: 2px solid #dfe1e6;
  font-size: 14px;
}

.inline-edit-input {
  border: 1px solid #979797;
  border-radius: 3px;
  padding: 8px 6px;
  font-size: 14px;
}

.progress-unit {
  padding: 0 3px;
  font-size: 14px;
  margin: 0;
}

.react-select__menu-portal {
  z-index: 99999999 !important;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.modal-heading {
  flex: 1;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #585858;
}

.modal-button {
  background-color: rgb(63, 199, 224) !important;
  border-radius: 15px !important;
  align-items: center !important;
  display: flex !important;
  flex: 1;
  margin: 20px 10% !important;
  padding: 25px !important;
}

.modal-button span {
  color: white !important;
  font-size: 20px !important;
}

.modal-button-danger {
  border-radius: 15px !important;
  align-items: center !important;
  display: flex !important;
  flex: 1;
  margin: 20px 10% !important;
  padding: 25px !important;
}

.trainerInfoInputs .inputWrapper {
  margin-bottom: 8px;
}

.date-input-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.Top {
  display: flex;
  align-items: center;
}

.icon-button {
  align-items: center;
  margin: 0 5px;
  padding: 20px 5px;
}

.save-button {
 margin-left: 10px;
}
.save-button span {
  color: #5D6A81;
 }
.del-button {
  /* margin-left: 5px;
  margin-right: 5px; */

 }
 .del-button span {
  color: black;
  padding-left: 13px;
  padding-right: 13px;
  }

.icon-button span {
  display: flex;
  align-self: center;
  color: white;
}

.two-columns-grid .trainer-details>div {
  margin: 0 0 20px 0;
}

.modalHeaderCloseButton:focus {
  box-shadow: none !important;
}

.fitlov-button[disabled] {
  opacity: 0.6;
}

.fitlov-button[disabled]>span {
  color: #303030 !important;
}

.date-select__clear-indicator {
  display: none !important;
}

.inlineEdit>form>div {
  display: flex;
}

tr[data-ts--dynamic-table--table-row--highlighted="true"] {
  background-color: #fff8e1 !important;
}

.fc-event-time {
  font-weight: 600;
  font-size: 13px !important;
}

.fc-event-title-container {
  font-size: 0.8rem;
  font-weight: 700;
  padding-top: 4px;
}

.fc-event-main {
  padding: 4px !important;
}