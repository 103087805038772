.home{
    flex: 4;
}

.buttonContainer{
    margin-bottom: 40;
    margin-left: 30;
}
.certificateInput{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
}